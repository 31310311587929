import {
  Button,
  Form,
  Image,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Table,
  Tooltip,
} from "antd";

import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { styled } from "styled-components";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import Utils from "utils";
import { permitKey, PermitValue } from "constants";
import {
  addNewBanHangCT,
  getBanHangCTByMabanhang,
  setBanHangCTList,
  updateBanHangCT,
  deleteBanHangCT,
  getTonBanHang,
} from "store/slices/sale/banhangSlice";

import { initBanHangCt } from "views/app-views/sale/managements/BanHang/BanLe";
import { isEmpty } from "lodash";
import { substr } from "stylis";

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    if (record["action"] !== "initial" && record["action"] !== "update") {
      return;
    }

    if (record["action"] !== "initial" && dataIndex === "tenhh") {
      return;
    }

    if (dataIndex === "tenhh") {
      restProps.dispatch(
        getTonBanHang({
          mabanhang: restProps.banHangId,
          makk: restProps.makk,
          madoituong: restProps.madoituong,
          ngay: new Date(restProps.ngay),
        })
      );
    }

    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const onChangeTenhh = (value) => {
    if (value === null) return;
    const data = restProps.tonBanHangList.find((i) => i.thuTu === value);

    form.setFieldValue([dataIndex], data.tenhh);
    handleSave(
      {
        ...record,
        mahh: data.mahh,
        maview: data.maview,
        tenhh: data.tenhh,
        tendonvitinh: data.tendonvitinh,
        dongia: data.dongia,
        dongiaton: data.dongia,
      },
      dataIndex
    );
  };
  const onChangeSoLuong = (value) => {
    if (value === null) return;
    handleSave(
      {
        ...record,
        dongia: record.dongiaton,
        giamgia: 0,
        giamtien: 0,
        phantramgiam: 0,
        thanhtien: record.dongiaton * value,
      },
      dataIndex
    );
  };
  const onChangeDonGia = (value) => {
    if (value === null) return;
    restProps.setIsSuaGiaBan(true);
    handleSave(
      {
        ...record,
        giamgia: 0,
        giamtien: 0,
        phantramgiam: 0,
        thanhtien: value * record.soluong,
      },
      dataIndex
    );
  };
  const onChangeGiamGia = (value) => {
    if (value === null) return;
    handleSave(
      {
        ...record,
        dongia: record.dongiaton - value,
        giamtien: 0,
        phantramgiam: 0,
        thanhtien: (record.dongiaton - value) * record.soluong,
      },
      dataIndex
    );
  };
  const onChangeGiamTien = (value) => {
    if (value === null) return;
    handleSave(
      {
        ...record,
        dongia: record.dongiaton,
        giamgia: 0,
        phantramgiam: 0,
        thanhtien: record.soluong * record.dongiaton - value,
      },
      dataIndex
    );
  };
  const onChangePhanTramGiamGia = (value) => {
    if (value === null) return;
    handleSave(
      {
        ...record,
        dongia: record.dongiaton - (record.dongiaton * value) / 100,
        giamgia: 0,
        giamtien: 0,
        thanhtien:
          (record.dongiaton - (record.dongiaton * value) / 100) *
          record.soluong,
      },
      dataIndex
    );
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values }, dataIndex);
    } catch (errInfo) {}
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      >
        {dataIndex === "soluong" ? (
          <InputNumber
            style={{ width: "100%" }}
            step={1}
            formatter={(value) => Utils.formatterNumber(value)}
            parser={(value) => parserNumber(value)}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            onChange={(value) => onChangeSoLuong(value)}
          />
        ) : dataIndex === "dongia" ? (
          <InputNumber
            style={{ width: "100%" }}
            step={1}
            formatter={(value) => Utils.formatterNumber(value)}
            parser={(value) => parserNumber(value)}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            onChange={(value) => onChangeDonGia(value)}
          />
        ) : dataIndex === "giamgia" ? (
          <InputNumber
            style={{ width: "100%" }}
            step={1}
            formatter={(value) => Utils.formatterNumber(value)}
            parser={(value) => parserNumber(value)}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            onChange={(value) => onChangeGiamGia(value)}
          />
        ) : dataIndex === "giamtien" ? (
          <InputNumber
            style={{ width: "100%" }}
            step={1}
            formatter={(value) => Utils.formatterNumber(value)}
            parser={(value) => parserNumber(value)}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            onChange={(value) => onChangeGiamTien(value)}
          />
        ) : dataIndex === "phantramgiam" ? (
          <InputNumber
            style={{ width: "100%" }}
            step={1}
            formatter={(value) => Utils.formatterNumber(value)}
            parser={(value) => parserNumber(value)}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            onChange={(value) => onChangePhanTramGiamGia(value)}
          />
        ) : dataIndex === "tenhh" ? (
          <Select
            style={{ width: "100%" }}
            placeholder="Chọn thuốc vật tư"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            onChange={(value) => onChangeTenhh(value)}
            dropdownStyle={{
              maxHeight: 300,
              overflow: "auto",
              minWidth: 800,
            }}
          >
            {restProps.tonBanHangList.map((x) => (
              <Select.Option label={x.tenhh} value={x.thuTu} key={x.thuTu}>
                <div style={{ display: "flex" }}>
                  <Image
                    src="https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg"
                    style={{ width: 50, height: 50 }}
                  />
                  <table style={{ borderCollapse: "collapse", width: "650px" }}>
                    <tr style={{ border: "1px solid #dddddd", width: "650px" }}>
                      <th
                        style={{
                          minWidth: "80px",
                          border: "1px solid #dddddd",
                        }}
                      >
                        Mã số
                      </th>
                      <th
                        style={{
                          minWidth: "250px",
                          border: "1px solid #dddddd",
                        }}
                      >
                        Tên thuốc vật tư
                      </th>
                      <th
                        style={{
                          minWidth: "60px",
                          border: "1px solid #dddddd",
                        }}
                      >
                        ĐVT
                      </th>
                      <th
                        style={{
                          minWidth: "80px",
                          border: "1px solid #dddddd",
                        }}
                      >
                        Hàm lượng
                      </th>
                      <th
                        style={{
                          minWidth: "80px",
                          border: "1px solid #dddddd",
                        }}
                      >
                        Đơn giá
                      </th>
                      <th
                        style={{
                          minWidth: "80px",
                          border: "1px solid #dddddd",
                          fixed: "right",
                        }}
                      >
                        Tồn kho
                      </th>
                    </tr>
                    <tr
                      style={{
                        border: "1px solid #dddddd",
                        width: "650px",
                        fixed: "right",
                      }}
                    >
                      <td
                        style={{
                          width: "80px",
                          border: "1px solid #dddddd",
                        }}
                      >
                        <Tooltip title={x.maview}>
                          {substr(x.maview, 0, 12) +
                            (x.maview.length > 12 ? "..." : "")}
                        </Tooltip>
                      </td>
                      <td
                        style={{
                          width: "250px",
                          border: "1px solid #dddddd",
                        }}
                      >
                        <Tooltip title={x.tenhh}>
                          {substr(x.tenhh, 0, 40) +
                            (x.tenhh.length > 40 ? "..." : "")}
                        </Tooltip>
                      </td>
                      <td
                        style={{
                          width: "60px",
                          border: "1px solid #dddddd",
                        }}
                      >
                        {x.tendonvitinh}
                      </td>
                      <td
                        style={{
                          width: "80px",
                          border: "1px solid #dddddd",
                        }}
                      >
                        {x.hamluong}
                      </td>
                      <td
                        style={{
                          width: "80px",
                          border: "1px solid #dddddd",
                          textAlign: "right",
                          fixed: "right",
                        }}
                      >
                        {Utils.formatterNumber(x.dongia)}
                      </td>
                      <td
                        style={{
                          width: "80px",
                          border: "1px solid #dddddd",
                          textAlign: "right",
                          color: "red",
                          fixed: "right",
                        }}
                      >
                        {Utils.formatterNumber(x.tonkho)}
                      </td>
                    </tr>
                  </table>
                </div>
              </Select.Option>
            ))}
          </Select>
        ) : (
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 10,
          height: 20,
          width: "100%",
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export const parserNumber = (val) => {
  if (!val) return 0;
  return Number.parseFloat(
    val.replace(/\$\s?|(\.*)/g, "").replace(/(\,{1})/g, ".")
  ).toFixed(5);
};
export default function TableBanLeCTList({
  banHangCTList,
  dmThuocVatTuList,
  banHangId,
  maKkxuat,
  ngayxuat,
  madoituong,
  permitList = [],
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { tonBanHangList } = useSelector((state) => state.banHangSale);
  const [isSuaGiaBan, setIsSuaGiaBan] = useState(false);

  const allowLock = Utils.checkPermitValue(
    PermitValue.khoa,
    permitList,
    permitKey.crm_hospital
  );
  const allowEdit = Utils.checkPermitValue(
    PermitValue.sua,
    permitList,
    permitKey.crm_hospital
  );
  const allowDelete = Utils.checkPermitValue(
    PermitValue.xoa,
    permitList,
    permitKey.crm_hospital
  );
  const onClickAction = (record, index) => {
    const newData = banHangCTList.slice(0);
    let dongiaban = null;

    if (isSuaGiaBan) {
      dongiaban = record.dongia;
      setIsSuaGiaBan(false);
    }
    const payload = {
      mahh: record.mahh,
      tenhh: record.tenhh,
      maview: record.maview,
      dongia: record.dongia,
      dongiaban: dongiaban,
      thanhtien: record.thanhtien,
      soluong: record.soluong,
      mabanhang: banHangId,
      mabanhangct: record.mabanhangct,
      cachdung: record.cachdung,
      giamgia: record.giamgia,
      giamtien: record.giamtien,
      phantramgiam: record.phantramgiam,
      onSuccess: () => {
        dispatch(
          getBanHangCTByMabanhang({
            mabanhang: banHangId,
            isNewCt: record.action === "initial",
          })
        );
      },
    };

    if (isEmpty(payload.tenhh)) {
      return;
    }

    if (record.action === initBanHangCt.action) {
      dispatch(addNewBanHangCT(payload));
    } else {
      if (record.thuTu !== null && record.action !== "update") {
        const indexX = newData.findIndex((item) => record.thuTu === item.thuTu);
        newData[indexX] = { ...newData[indexX], action: "update" };
        dispatch(setBanHangCTList(newData));
      } else {
        dispatch(updateBanHangCT(payload));
      }
    }
  };
  const tableColumns = useMemo(
    () => [
      {
        title: "STT",
        width: 40,
        align: "center",
        dataIndex: "thuTu",
      },
      {
        title: "Mã số",
        width: 60,
        dataIndex: "maview",
      },
      {
        title: "Tên thuốc, vật tư",
        width: 180,
        dataIndex: "tenhh",
        editable: true,
        render: (txt, record) => (
          <Tooltip title={record.tenhh}>
            <div>
              {!isEmpty(record.tenhh) ? (
                record.tenhh.substr(0, 45) +
                (record.tenhh?.length > 45 ? "..." : "")
              ) : (
                <i
                  style={{
                    fontSize: "16px",
                    color: "blue",
                    paddingLeft: "20px",
                  }}
                >
                  Chọn thuốc, vật tư...
                </i>
              )}
            </div>
          </Tooltip>
        ),
      },
      {
        title: "ĐVT",
        width: 50,
        dataIndex: "tendonvitinh",
      },
      {
        title: <Tooltip title="Số lượng">Số lượng</Tooltip>,
        width: 70,
        dataIndex: "soluong",
        align: "right",
        editable: true,
        isMoney: true,
        render: (value) => (value ? Utils.formatterNumber(value) : 0),
      },
      {
        title: "Đơn giá",
        width: 80,
        dataIndex: "dongia",
        align: "right",
        editable: true,
        render: (value) => (value ? Utils.formatterNumber(value) : 0),
      },
      {
        title: <Tooltip title="Giảm % vào giá bán">% giảm</Tooltip>,
        width: 65,
        dataIndex: "phantramgiam",
        align: "right",
        editable: true,
        render: (value) => (value ? Utils.formatterNumber(value) : 0),
      },
      {
        title: <Tooltip title="Giảm thẳng vào giá bán">Giảm giá</Tooltip>,
        width: 70,
        dataIndex: "giamgia",
        align: "right",
        editable: true,
        render: (value) => (value ? Utils.formatterNumber(value) : 0),
      },
      {
        title: <Tooltip title="Giảm thẳng vào thành tiền">Giảm tiền</Tooltip>,
        width: 70,
        dataIndex: "giamtien",
        align: "right",
        editable: true,
        render: (value) => (value ? Utils.formatterNumber(value) : 0),
      },
      {
        title: "Thành tiền",
        width: 75,
        dataIndex: "thanhtien",
        align: "right",
        render: (value) => (value ? Utils.formatterNumber(value) : 0),
      },
      {
        fixed: "right",
        width: 95,
        align: "center",
        dataIndex: "action",
        render: (_, record, index) => (
          <>
            {record.action === "initial" ? (
              <Button
                disabled={
                  !record.tenhh || banHangId === null || banHangId === 0
                }
                title={"Thêm chi tiết"}
                onClick={() => onClickAction(record, index)}
                className="mr-2"
                icon={<PlusOutlined />}
                shape="circle"
              />
            ) : (
              <>
                <Button
                  title={
                    record.action === "update" ? "Lưu chi tiết" : "Sửa chi tiết"
                  }
                  disabled={
                    banHangCTList.filter((x) => x.action === "update").length >
                      0 &&
                    record.thuTu !==
                      banHangCTList.filter((x) => x.action === "update")[0]
                        ?.thuTu
                  }
                  onClick={() => onClickAction(record, index)}
                  className="mr-2"
                  icon={
                    record.action === "update" ? (
                      <SaveOutlined />
                    ) : (
                      <EditOutlined />
                    )
                  }
                  shape="circle"
                />
                <Popconfirm
                  title="Bạn có muốn xóa chi tiết?"
                  placement="topLeft"
                  onConfirm={() => {
                    const payload = {
                      mabanhangct: record.mabanhangct,
                      mahh: record.mahh,
                      tenhh: record.tenhh,
                      maview: record.maview,
                      dongia: record.dongia,
                      thanhtien: record.thanhtien,
                      soluong: record.soluong,
                      mabanhang: banHangId,
                      cachdung: record.cachdung,
                      onSuccess: () => {
                        dispatch(
                          getBanHangCTByMabanhang({
                            mabanhang: banHangId,
                            isNewCt: false,
                          })
                        );
                      },
                    };
                    dispatch(deleteBanHangCT(payload));
                  }}
                >
                  <Button
                    title={"Xóa chi tiết"}
                    //disabled={!allowView(PermitValue.xoa)}
                    className="mr-2"
                    icon={<DeleteOutlined type="primary" />}
                    shape="circle"
                  />
                </Popconfirm>
              </>
            )}
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [banHangCTList]
  );

  const handleSave = (row) => {
    const newData = banHangCTList.slice(0);
    const index = newData.findIndex((item) => row.thuTu === item.thuTu);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    dispatch(setBanHangCTList(newData));
  };

  const columns = tableColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
        isDate: col.isDate,
        isMoney: col.isMoney,
        dmThuocVatTuList: dmThuocVatTuList,
        banHangCTList: banHangCTList,
        dispatch: dispatch,
        banHangId: banHangId,
        ngay: ngayxuat,
        makk: maKkxuat,
        madoituong: madoituong,
        tonBanHangList: tonBanHangList,
        setIsSuaGiaBan: setIsSuaGiaBan,
        isSuaGiaBan: isSuaGiaBan,
      }),
    };
  });

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  return (
    <Form form={form} component={false}>
      <CustomTable
        components={components}
        columns={columns}
        dataSource={banHangCTList}
        rowKey={(_, index) => index}
        bordered
        style={{ padding: "0px" }}
        fixed={true}
        pagination={false}
        scroll={{ x: 950, y: "calc(100vh - 350px)" }}
        footer={(banHangCTList) => (
          <div>
            <div>
              Số khoản:
              <b style={{ color: "green", paddingLeft: "5px" }}>
                {Utils.formatterNumber(
                  banHangCTList.filter((x) => x.mabanhangct).length
                )}
              </b>
            </div>
            <div>
              Tổng tiền:
              <b style={{ color: "red", paddingLeft: "5px" }}>
                {Utils.formatterNumber(
                  banHangCTList
                    .filter((x) => x.mabanhangct)
                    .reduce(
                      (prev, current) => prev + current?.thanhtien || 0,
                      0
                    )
                )}
              </b>
            </div>
          </div>
        )}
      />
    </Form>
  );
}

const CustomTable = styled(Table)`
  // .ant-table-tbody > tr > td {
  //   padding: 2px;
  // }
`;
